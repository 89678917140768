import { DEFAULT_PATHS } from 'config.js';
import { USER_ROLE } from 'constants.js';
import { lazy } from 'react';

const instructorData = {
  data: lazy(() =>
    import('views/instructorView/instructorData/InstructorData')
  ),
};

const appRoot = DEFAULT_PATHS.APP.endsWith('/')
  ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length)
  : DEFAULT_PATHS.APP;

const InstructorDataRoute = {
  path: `${appRoot}/meus-dados`,
  exact: true,
  icon: 'database',
  label: 'menu.my-data',
  component: instructorData.data,
  protected: true,
  roles: [USER_ROLE.Instructor],
  to: `${appRoot}/meus-dados`,
};

export default InstructorDataRoute;
