/* eslint-disable */
import dashRoute from 'views/dashboards/routes';
import usersRoute from 'views/users/routes';
import parametersRoute from 'views/parameters/routes';
import eventsCalendarRoute from 'views/eventsCalendar/routes';
import notificationsRoute from 'views/notification/routes';
import studentHistoryRoute from 'views/studentHistory/routes';
import registrationRoute from 'views/registration/routes';
import InstructorDataRoute from 'views/instructorView/instructorData/routes';
import instructorProfileRoute from 'views/instructorView/classesPage/routes';
import lessonPlansRoute from 'views/lessonPlans/routes';
import studentEnrollmentRoute from 'views/studentEnrollment/routes';
import profileRoute from 'views/profile/routes';
import reportRoute from 'views/report/routes';

// import { USER_ROLE } from 'constants.js';

// const report = {
//   path: '#',
//   icon: 'arrow-end-bottom',
//   label: 'commons.report',
//   roles: [USER_ROLE.Admin],
//   isReport: true,
// };

const routesAndMenuItems = {
  mainMenuItems: [
    dashRoute,
    eventsCalendarRoute,
    usersRoute,
    parametersRoute,
    registrationRoute,
    lessonPlansRoute,

    instructorProfileRoute,
    studentEnrollmentRoute,
    profileRoute,
    notificationsRoute,
    reportRoute,
  ],
  sidebarItems: [],
};
export default routesAndMenuItems;
