import { lazy } from 'react';
import { USER_ROLE } from 'constants.js';
import { DEFAULT_PATHS } from 'config.js';

const studentHistory = {
  history: lazy(() => import('views/studentHistory/HistoryPage')),
  frequency: lazy(() => import('views/studentHistory/FrequencyPage')),
  grades: lazy(() => import('views/studentHistory/GradePage')),
};
const appRoot = DEFAULT_PATHS.APP.endsWith('/')
  ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length)
  : DEFAULT_PATHS.APP;

const studentHistoryRoute = {
  path: `${appRoot}/historico-do-aluno`,
  exact: true,
  icon: 'clock',
  label: 'menu.student-history',
  component: studentHistory.history,
  protected: true,
  roles: [USER_ROLE.Admin],
  to: `${appRoot}/historico-do-aluno`,
  subs: [
    {
      path: '/frequencia',
      label: 'history.frequency',
      component: studentHistory.frequency,
      hideInMenu: true,
    },
    {
      path: '/notas',
      label: 'commons.grades',
      component: studentHistory.grades,
      hideInMenu: true,
    },
  ],
};

export default studentHistoryRoute;
